//custom variables go here

$body-bg: #dde2e7;

$font-family-sans-serif: 'proxima-nova', sans-serif !default;
$font-size-base: 15px;
$headings-font-weight: 700;

$default-margin: 20px;
$padding-base-vertical: 4px;

$brand-primary: #00579a;
$brand-primary-dark: #00467a;
$brand-primary-transparent: rgba(0, 87, 153, 0.8);
$brand-primary-transparent-2: rgba(0, 87, 153, 0);
$brand-primary-light: #008fc5;
$brand-primary-lighter: #dfedf4;
$brand-secondary: #ffb400;
$brand-secondary-light: #fed675;
$brand-secondary-lighter: #fde5ab;
$brand-danger: #eb4d00;

$link-color: $brand-secondary;

$grid-gutter-width: 20px;

$default-footer-background: $brand-primary;
$default-footer-link-color: #fff;

$navbar-height: 58px;
$navbar-border-radius: 0;

$navbar-inverse-color: #fff;
$navbar-inverse-bg: transparent;
$navbar-inverse-link-color: #fff;
$navbar-inverse-link-hover-color: #fff;
$navbar-inverse-link-hover-bg: transparent !default;
$navbar-inverse-link-active-color: #fff;
$navbar-inverse-link-active-bg: rgba(255, 255, 255, 0.1);
$navbar-inverse-toggle-hover-bg: rgba(0, 0, 0, 0.3);
$navbar-inverse-toggle-border-color: transparent;

$screen-md: 992px;
$grid-float-breakpoint: $screen-md;

$container-tablet: 100%;
$container-desktop: 980px;
$container-large-desktop: 980px;

$container-padding: 20px;