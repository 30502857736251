.footer {
  padding: 0;
  margin: 0;
  background: transparent;

  .morecast-logo {
    width: 200px;
    height: auto;

    @media (max-width: $grid-float-breakpoint-max) {
      width: 180px;
    }
  }

  ul li {
    padding: 2px 0;
  }
}

.footer-inner {
  padding: 20px 0 15px 25px;
  font-size: 14px;
  background: $default-footer-background;
  @include clearfix;

  .footer-row {
    padding: 0 235px $default-margin 0;
    position: relative;

    .footer-img {
      position: absolute;
      right: 0;
      bottom: -65px;
    }
  }

  @media (max-width: $grid-float-breakpoint-max) {
    padding: 20px 20px 0;

    .footer-row {
      padding-right: 0;
      padding-bottom: 0;

      .footer-img {
        display: none;
      }
    }
  }
}

.footer-social {
  background: #003f70;
  padding: 10px 25px;
  height: 50px;
}