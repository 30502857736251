.community-header {
  position: relative;
  height: 310px;
  width: 100%;
  background-color: #fff;
  background-position: center center;
  background-size: cover;
  margin: $default-margin 0;

  .user-info {
    position: absolute;
    left: 15px;
    top: 15px;
    padding-left: 115px;

    &.no-user-img {
      padding-left: 15px;
    }

    .user-img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100px;
      height: 100px;
      background-position: center center;
      background-size: cover;
      @include border-radius(50%);
      @include box-shadow(0 3px 7px rgba(0, 0, 0, 0.5));
    }

    .user-name {
      margin-top: 3px;
      color: #fff;
      font-size: 36px;
      text-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
    }
  }

  .share-weather-btn {
    position: absolute;
    left: 15px;
    bottom: 15px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .community-header-tabs {
    position: absolute;
    right: 15px;
    bottom: 0;
    margin: 0;
    list-style: none;
    padding: 0;

    & > li {
      float: left;
      display: block;

      a {
        display: block;
        padding: 12px 15px;
        @include border-top-radius($border-radius-base);
        background: #979696;
        color: #fff;
        margin-left: 10px;

        &:focus,
        &:active,
        &:hover {
          text-decoration: none;
          background: #888;
        }
      }

      &.active a {
        background: $body-bg;
        color: $text-color;
      }
    }

    @media (max-width: $screen-xs-max) {
      & > li {
        a {
          max-width: 120px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    height: 250px;

    .user-info {
      padding-left: 75px;

      .user-img {
        width: 60px;
        height: 60px;
      }

      .user-name {
        font-size: 28px;
      }
    }

    .share-weather-btn {
      position: absolute;
      bottom: 60px;
      right: 15px;
      left: auto;
    }
  }
}

.community-container {
  position: relative;
  min-height: 600px;

  .community-content {
    margin-right: 320px;
  }

  .community-sidebar {
    width: 300px;
    position: absolute;
    right: 0;
    top: 0;
  }

  @media (max-width: $screen-xs-max) {
    .community-content {
      margin-right: 0;
    }

    .community-sidebar {
      width: auto;
      position: static;
    }
  }
}

.community-box {
  background: #fff;
  margin-bottom: $default-margin;

  .community-box-header {
    position: relative;
    display: block;
    padding: 0 10px;
    line-height: 50px;
    color: #000;
    border-bottom: 1px solid $body-bg;
    min-height: 50px;
  }

  a.community-box-header {
    &:after {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -5px;
      @include caret('top', 5px, $text-color);
    }

    &.collapsed:after {
      @include caret('bottom', 5px, $text-color);
    }
  }

  .community-box-content {
    @include clearfix;
  }

  .radio {
    margin: 15px 0;

    label {
      margin: 0;
      padding: 0;
    }

    input[type=radio].input-radio + .form-add {
      border: 2px solid #ddd;
    }
  }

  .championship-row {
    position: relative;
    padding-left: 35px;
    line-height: 25px;
    margin: 8px 0;

    .user-img {
      position: absolute;
      left: 0;
      top: 0;
      width: 25px;
      height: 25px;
      @include border-radius(50%);
      background-color: #f5f5f5;
      background-size: cover;
      background-position: center center;
    }

    a {
      &,
      &:hover,
      &:focus,
      &:active {
        color: $text-color;
      }
    }

    &.championship-row-1 {
      fill: #fbb900;
    }

    &.championship-row-2 {
      fill: #b2b2b2;
    }

    &.championship-row-3 {
      fill: #d78c62;
    }
  }

  .alert-header {
    position: relative;
    padding: 10px 10px 10px 45px;
    line-height: 1em;
    border: 0;

    .created-date {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 12px;
    }

    a {
      color: $text-color;
    }

    .user-img {
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -15px;
      width: 30px;
      height: 30px;
      @include border-radius(50%);
      background-color: #f5f5f5;
      background-size: cover;
      background-position: center center;
    }
  }

  .button-row {
    border-bottom: 1px solid #ccc;

    button {
      padding: 0 30px;
      height: 35px;
      background: transparent;
      border: 0;

      &:focus {
        outline: none;
      }
    }
  }

  .btn-like-alert.active {
    svg {
      fill: $brand-secondary;
    }
  }

  .small-heart-icon {
    width: 18px;
    height: 18px;
  }

  .comments-container {
    padding: 0 10px 10px;
    max-height: 340px;
    overflow: auto;

    a {
      color: $text-color;
    }
  }

  .comments {
    list-style: none;
    margin: 0;
    padding: 0;
    @include clearfix;


    li {
      position: relative;
      display: block;
      min-height: 30px;
      margin: 10px 0;
      padding: 0 0 0 40px;

      &:last-child {
        margin-bottom: 0;
      }

      &.default-hidden {
        display: none;
      }

      a {
        color: $text-color;
        font-weight: bold;
      }
    }

    .user-img {
      position: absolute;
      left: 0;
      top: 0;
      width: 30px;
      height: 30px;
      @include border-radius(50%);
      background-color: #f5f5f5;
      background-size: cover;
      background-position: center center;
    }
  }

  .add-comment {
    position: relative;
    padding: 5px 5px 5px 50px;
    border-top: 1px solid #ccc;

    .form-control {
      border: 1px solid $brand-primary;
    }

    .user-img {
      position: absolute;
      left: 10px;
      top: 5px;
      width: 30px;
      height: 30px;
      @include border-radius(50%);
      background-color: #f5f5f5;
      background-size: cover;
      background-position: center center;
    }
  }
}

.community-grid {
  margin: 0 -10px;

  &[data-columns]::before {
    @media (min-width: $screen-sm){
      content: '2 .column.size-1of2';
    }

    @media (max-width: $screen-xs-max){
      content: '1 .column.size-1of1';
    }
  }

  .community-box {
    display: none;
  }

  .column .community-box {
    display: block;
  }

  .column {
    padding: 0 10px;
  }

  .size-1of1 {
    width: 100%;
  }

  .size-1of2 {
    float: left;
    width: 50%;
  }
}

.select-link {
  display: block;
  color: $text-color;
  position: relative;
  padding: 5px 10px 5px 25px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -8px;
    @include border-radius(50%);
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
  }

  &:hover,
  &:active,
  &:focus {
    color: $text-color;
  }

  &.active {
    &:after {
      content: '';
      position: absolute;
      left: 3px;
      top: 50%;
      margin-top: -5px;
      @include border-radius(50%);
      width: 10px;
      height: 10px;
      background: $text-color;
    }
  }
}

.drop-file-box {
  text-align: center;
  border: 2px dashed #7f7f7f;
  padding: 15px;
}

.visible-active {
  display: none;
}

.active {
  .visible-active {
    display: inline-block;
  }
  .hidden-active {
    display: none;
  }
}

.btn-change-cover-image {
  position: absolute;
  right: 10px;
  top: 10px;
}

.input-check-social-icon {
  width: 60px;
  height: 60px;
}

.input-check-social-holder {
  margin: 0 5px;
  display: inline-block;

  label {
    padding: 0;
  }
}

.share-step {
  position: relative;
  padding: 0 0 30px 50px;

  .step-content {
    @include opacity(0.3);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    bottom: 0;
    width: 4px;
    background: #999;
  }

  &.finished {
    &:after {
      background: $brand-secondary;
    }
  }

  &.last {
    &:after {
      display: none;
    }
  }

  .share-step-number {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    @include border-radius(50%);
    background: #999;
    color: #fff;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }

  &.active {
    .step-content {
      @include opacity(1);
    }

    .share-step-number {
      background: $brand-secondary;
      color: $brand-primary;
    }
  }
}

input[type=checkbox] {
  &.input-check-social {
    display: none;

    & + .form-add {
      display: none;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 30px;
      height: 30px;
      border: 2px solid transparent;
      background: $brand-secondary;
      @include border-radius(50%);

      img {
        display: block;
        width: 20px;
        height: 20px;
        margin: 4px 0 0 3px;
      }
    }

    &:checked + .form-add {
      display: block;
    }
  }
}

.follower-list {
  list-style: none;
  margin: 0 -15px;
  padding: 0;
  border-top: 1px solid $brand-primary-light;
  border-bottom: 1px solid $brand-primary-light;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;

  li {
    margin: 0;
    border-bottom: 1px solid $brand-primary-light;

    &:last-child {
      border-bottom: 0;
    }

    .user-img-circle {
      padding-bottom: 0;
      width: 55px;
      height: 55px;
    }

    table td {
      vertical-align: middle;
      height: 70px;
    }
  }

}

.black-link,
.black-link:active,
.black-link:focus,
.black-link:hover {
  color: $text-color;
}

.btn-unfollow {
  display: none;
}

.i-follow {
  .btn-follow {
    display: none;
  }

  .btn-unfollow {
    display: inline-block;
  }
}

.community-results-row {
  line-height: 30px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  .results-icon {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    fill: $brand-secondary;
  }
}

.webcam-iframe-holder {
  background: #f6f6f6;
}

.rating-box-holder {
  line-height: 36px;
}

.rating-box {
  vertical-align: middle;
  display: inline-block;
  fill: #c8c8c8;
}