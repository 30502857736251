.alert-white {
  @include gradient-vertical(#fff, #f9f9f9);

  display: table;
  position: relative;
  width: 100%;
  padding-left: 61px;
  color: #404040;
  border-color: #ddd #ccc #bbb;

  .icon {
    @include border-radius(3px 0 0 3px);

    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: 45px;
    padding-top: 15px;
    text-align: center;
    border: 1px solid darken($brand-primary, 5%);
    background: $brand-primary;

    &:after {
      display: block;
      position: absolute;
      top: 50%;
      right: -6px;
      width: 10px;
      height: 10px;
      margin-top: -4px;
      content: ' ';
      transform: rotate(45deg);
      border: 0;
      border-top: 1px solid darken($brand-primary, 5%);
      border-right: 1px solid darken($brand-primary, 5%);
      background: $brand-primary;
    }

    i {
      font-size: 1.4em;
      position: absolute;
      top: 50%;
      left: 12px;
      margin-top: -10px;
      color: #fff;
    }
  }

  &.alert-danger .icon,
  &.alert-danger .icon::after {
    border-color: darken($brand-danger, 5%);
    background: $brand-danger;
  }

  &.alert-info .icon,
  &.alert-info .icon::after {
    border-color: darken($brand-info, 5%);
    background: $brand-info;
  }

  &.alert-warning .icon,
  &.alert-warning .icon::after {
    border-color: darken($brand-warning, 5%);
    background: $brand-warning;
  }

  &.alert-success .icon,
  &.alert-success .icon::after {
    border-color: darken($brand-success, 5%);
    background: $brand-success;
  }
}
