.navbar {
  margin-bottom: 0;
}

.navbar-colored {
  border-color: darken($default-navbar-color, 5%);
  background-color: $default-navbar-color;

  .navbar-brand {
    color: #fff;
    &:hover,
    &:focus {
      color: #eee;
    }
  }

  .navbar-text {
    color: #fff;
  }

  .navbar-nav {
    > li > a {
      color: #fff;

      &:hover,
      &:focus {
        color: #fff;
        background-color: lighten($default-navbar-color, 5%);
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: #fff;
        background-color: lighten($default-navbar-color, 5%);
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: #fff;
        background-color: lighten($default-navbar-color, 5%);
      }
    }
  }

  .navbar-toggle {
    border-color: #fff;
    &:hover,
    &:focus {
      background-color: lighten($default-navbar-color, 5%);
    }
    .icon-bar {
      background-color: #fff;
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: darken($default-navbar-color, 7%);
  }

  .navbar-nav {
    > .open > a {
      &,
      &:hover,
      &:focus {
        color: #fff;
        background-color: darken($default-navbar-color, 5%);
      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      .open .dropdown-menu {
        > .dropdown-header {
          border-color: darken($default-navbar-color, 5%);
        }
        .divider {
          background-color: darken($default-navbar-color, 5%);
        }
        > li > a {
          color: #fff;
          &:hover,
          &:focus {
            color: #fff;
            background-color: lighten($default-navbar-color, 5%);
          }
        }
        > .active > a,
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: #fff;
            background-color: lighten($default-navbar-color, 5%);
          }
        }
      }
    }
  }

  .navbar-link {
    color: #fff;
    &:hover {
      color: #eee;
    }
  }

  .btn-link {
    color: #fff;
    &:hover,
    &:focus {
      color: #eee;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: #eee;
      }
    }
  }
}

.navbar-transparent {
  @include transition(background .3s ease-in);

  border-color: transparent;
  background-color: rgba(0, 0, 0, 0);

  &.is-scrolled {
    background: $nav-transparent-bg;
  }

  .navbar-brand {
    color: $nav-transparent-color;
    &:hover,
    &:focus {
      color: $nav-transparent-color-hover;
    }
  }

  .navbar-text {
    color: $nav-transparent-color;
  }

  .navbar-nav {
    > li > a {
      color: $nav-transparent-color;
      &:hover,
      &:focus {
        color: $nav-transparent-color-hover;
        background-color: transparent;
      }
    }
    > .disabled > a,
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: $nav-transparent-color-hover;
        background: transparent;
      }
    }
  }

  .navbar-toggle {
    border-color: $nav-transparent-color;
    .icon-bar {
      background-color: $nav-transparent-color;
    }
    &:hover,
    &:focus {
      border-color: $nav-transparent-color-hover;
      background: transparent;
      .icon-bar {
        background-color: $nav-transparent-color-hover;
      }
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: transparent;
  }

  @media (max-width: $grid-float-breakpoint-max) {
    .navbar-collapse,
    .navbar-form {
      @include transition(all .3s ease-in);

      border-color: $nav-transparent-color;
      border-bottom: 1px solid #000;
      background: $nav-transparent-bg;
    }
    &.is-scrolled {
      .navbar-collapse,
      .navbar-form {
        background: transparent;
      }
    }
  }

  .navbar-nav {
    > .open > a {
      &,
      &:hover,
      &:focus {
        color: $nav-transparent-color-hover;
        background-color: transparent;
      }
    }

    > .open > .dropdown-menu {
      @include border-radius(3px);
      &:after {
        @include caret('top', 10px);

        position: absolute;
        bottom: 100%;
        left: 20px;
      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      .open .dropdown-menu {
        &:after {
          display: none;
        }
        > .dropdown-header {
          border-color: $nav-transparent-color;
        }
        .divider {
          background-color: $nav-transparent-color;
        }
        > li > a {
          color: $nav-transparent-color;
          &:hover,
          &:focus {
            color: $nav-transparent-color-hover;
            background-color: transparent;
          }
        }
        > .active > a,
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: $nav-transparent-color-hover;
            background-color: transparent;
          }
        }
      }
    }
  }

  .navbar-link {
    color: $nav-transparent-color;
    &:hover {
      color: $nav-transparent-color-hover;
    }
  }

  .btn-link {
    color: $nav-transparent-color;
    &:hover,
    &:focus {
      color: $nav-transparent-color-hover;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: $nav-transparent-color;
      }
    }
  }
}
