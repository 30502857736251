@mixin bg-variant-full($parent, $color) {
  #{$parent} {
    color: #fff;
    background-color: $color;
  }
  a#{$parent}:hover,
  a#{$parent}:focus {
    color: #fff;
    background-color: darken($color, 10%);
  }
}

@include bg-variant-full('.bg-primary-full', $brand-primary);
@include bg-variant-full('.bg-success-full', $brand-success);
@include bg-variant-full('.bg-info-full', $brand-info);
@include bg-variant-full('.bg-warning-full', $brand-warning);
@include bg-variant-full('.bg-danger-full', $brand-danger);
@include bg-variant-full('.bg-dark', $brand-dark);
