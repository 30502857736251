/*
//Include to add Sprites to your CSS File
@import "sprites/sprite-...";
@include sprites($spritesheet-sprites);
//@include retina-sprites($retina-groups); //Include if "enable_retina_sprites" is true
*/

@import 'mixins';
@import 'header';
@import 'footer';
@import 'map';
@import 'icons';
@import 'community';

body,
html {
  max-width: 100%;

  @media (max-width: $screen-sm-max) {
    overflow: hidden;
    overflow-y: auto;
  }
}

.footer-push {
  height: 313px;

  @media (max-width: $screen-xs-max) {
    height: 445px;
  }
}

body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

.btn-primary {
  fill: #fff;
}

.svg-primary {
  fill: $brand-primary;
}

.svg-secondary {
  fill: $brand-secondary;
}

body .video-js-responsive {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.svg-white {
  fill: #fff;
}

.white-link {
  &,
  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
}

.champion-heart {
  height: 15px;
  width: 15px;
}

.ranking-flag {
  width: 25px;
  height: auto;
  vertical-align: middle;
  margin-right: 3px;
}

.bg-primary-lighter-full {
  background: $brand-primary-lighter;
}

.alert-danger {
  background: $brand-danger;
  border-color: $brand-danger;
  color: #fff;

  a {
    color: #fff;
    text-decoration: underline;
  }
}

.caret-right {
  @include caret('right');
  margin-left: 5px;
  margin-top: -2px;

  &.primary {
    border-left-color: $brand-primary;
  }

  &.black {
    border-left-color: $text-color;
  }
}

.caret-left {
  @include caret('left', 5px, #fff);
  margin-left: 5px;
  margin-top: -2px;

  &.primary {
    border-right-color: $brand-primary;
  }
}

svg {
  vertical-align: middle;
  transform-origin: 50% 50%;
}

.table-unstyled {
  width: 100%;
  border-collapse: collapse;

  td, th {
    padding: 2px 5px;
  }
}

.menu-settings {
  background: $brand-primary-light !important;
  @include border-radius(0);
  min-width: 0;

  li {
    padding: 5px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    &:last-child {
      border: 0;
    }
  }
}

.social-buttons {
  a {
    display: inline-block;
    width: 30px;
    height: 30px;

    /*
    &.facebook {
      background: #3b5998;
    }
    &.google-plus {
      background: #da4835;
    }
    &.twitter {
      background: #55acee;
    }
    &.instagram {
      background: #305f84;
    }
    &.youtube {
      background: #e12f2f;
    }
    */
  }
}

.btn-secondary {
  @include button-variant($brand-primary, $brand-secondary, $brand-secondary);
}

.btn-secondary-2 {
  @include button-variant(#000, $brand-secondary, $brand-secondary);
  color: #000;
  font-weight: bold;
  padding-right: 20px;
  position: relative;
  text-align: left;

  &:before {
    @include caret('right', 5px, #000);
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -5px;
  }
}

.btn-grey {
  @include button-variant(#fff, #969696, #969696);
}

.btn-transparent {
  @include button-variant(#fff, rgba(0, 0, 0, 0.7), transparent);
}

.btn-select {
  @include button-variant($text-color, #fff, $brand-primary);

  svg {
    width: 15px;
    height: 15px;
  }
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.ad-center-container {
  & > div {
    margin: 0 auto;
  }
}

.icon-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.side-ad-container {
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 20px;
  z-index: 100;
}

.container {
  margin: 0;
  padding-left: $container-padding;
  padding-right: $container-padding;

  @media (max-width: $grid-float-breakpoint-max) {
    &.outer-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media (max-width: $screen-xs-max) {
    padding-left: 0;
    padding-right: 0;
  }
}

.weather-globe-iframe {
  width: 100%;
  height: 70vh;
  border: none;
  background: #000;
  display: block;
}

.is-fullscreen .weather-globe-iframe {
  height: 100%;
}

.caret {
  margin-top: -2px;
}

.modal-dialog {
  .modal-content {
    @include border-radius(0);
    border: 0;
  }

  .modal-header {
    border: 0;
    text-align: center;

    .close {
      color: $text-color;
      text-shadow: none;
      opacity: 1;
      font-size: 27px;
    }
  }
}

.content-box {
  background: #fff;
  padding: 0 20px 20px;
  margin-bottom: $default-margin;
  @include clearfix;

  &.content-box-blue {
    background: url('../img/content-box-blue-bg.jpg') center center no-repeat;
    background-size: cover;
  }

  &.p-b-0 {
    padding-bottom: 0;
  }

  @media (max-width: $screen-xs-max) {
    padding-left: 0;
    padding-right: 0;

    .content-box-title {
      padding-left: $default-margin;
      padding-right: $default-margin;
    }
  }

  .content-box-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: $default-margin;
    padding-top: $default-margin;

    h1, h2, h3, h4, h5, h6 {
      line-height: $line-height-base;
      font-size: 20px;
      margin: 0;
    }
  }

  .content-box-title-blue {
    background: $brand-primary;
    padding: 7px 15px;
    height: 40px;
    margin: 0 0 $default-margin;

    @media (min-width: $screen-sm) {
      margin: 0 -20px $default-margin -20px;
    }

    color: #fff;
    line-height: 28px;

    a {
      color: #fff;
      text-decoration: underline;
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight: normal;
      line-height: $line-height-base;
      font-size: 20px;
      margin: 0;
    }
  }

  .content-box-content {
    margin-top: $default-margin;
  }

  .news-overview {
    display: block;
    position: relative;
    margin-bottom: $default-margin;
    min-height: 100px;
    color: $text-color;

    &.image-side {
      padding: 0 0 0 140px;

      @media (max-width: $screen-xs-max) {
        overflow: hidden;
        //max-height: 150px;
        padding-right: $default-margin;
      }

      .news-overview-img {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 120px;
        height: auto;
      }
    }

    &:hover,
    &:focus {
      @include opacity(0.8);
      text-decoration: none;
    }
  }

  .news-overview-img {
    width: 100%;
    height: 180px;
    background-position: center center;
    background-size: cover;
  }

  .news-overview-category {
    text-transform: uppercase;
    color: #9398a0;
    font-size: 14px;
    margin-bottom: 3px;
  }

  .news-overview-title {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 5px;
  }

  .news-overview-teaser {
    font-size: 16px;
  }

  .news-overview-main {
    .news-overview-title {
      font-size: 28px;
    }
  }

  .news-overview-main-img-container {
    margin-top: -20px;
    position: relative;

    .play-btn {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 60px;
      height: 60px;
      margin: -30px 0 0 -30px;
      padding-left: 5px;
      opacity: .9;
      fill: #fff;
      background: #ffb400;
      border-radius: 50%;
      text-align: center;
      line-height: 60px;
      @include box-shadow(0 3px 40px rgba(0, 0, 0, 0.6));
    }

    @media (min-width: $screen-sm) {
      margin-left: -20px;
    }
  }

  .news-overview-author {
    position: relative;
    margin-top: 3px;
    color: #9398a0;
    padding-left: 30px;

    .author-img {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 3px;
      border-radius: 50%;
      background-color: #f5f5f5;
      background-size: cover;
      background-position: center center;
      vertical-align: middle;
    }
  }

  .weather-moment-box-img {
    position: relative;
    display: block;
    height: 100px;
    width: auto;
    background-position: center center;
    background-size: cover;
    margin-bottom: $default-margin;

    &.large {
      height: 220px;
    }

    &:hover,
    &:focus {
      @include opacity(0.8);
      text-decoration: none;
    }

    .weather-moment-box-user-img {
      width: 30px;
      height: 30px;
      @include border-radius(50%);
      @include box-shadow(0 0 3px rgba(0, 0, 0, 0.3));
      margin-right: 5px;
      display: inline-block;
    }

    .weather-moment-box-img-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      color: #fff;
      font-weight: bold;
      padding: 5px 10px;
      @include primary-gradient-bg;
    }
  }
}

.form-control {
  border-color: $brand-primary;
  @include box-shadow(none);
}

.location-info-container .form-control {
  border: 0;
  padding-right: 28px;
}

.plan-route-input-container .form-control {
  border: 0;
}

.form-group.has-overlay {
  position: relative;

  .form-control {
    padding-right: 30px;
  }

  .input-overlay {
    position: absolute;
    right: 5px;
    top: 6px;
    width: 20px;
    height: 20px;
    fill: $text-color;
  }
}

.box-link {
  position: relative;
  display: block;
  width: 100%;
  height: 250px;
  background-position: center center;
  background-size: cover;
  color: #fff;
  margin-bottom: $default-margin;

  &:hover,
  &:focus {
    color: #fff;
    @include opacity(0.8);
    text-decoration: none;
  }

  &.graph {
    .box-link-overlay {
      background: $brand-primary-light;
      border-top: 1px solid #fff;

      &:before {
        display: none;
      }
    }
  }

  .box-link-btn {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    width: auto;
    display: block;
  }

  .box-link-info-text {
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
  }

  .box-video-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .box-video {
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -222px;
    background: #000;
    height: 250px;
    width: 445px;
  }

  .box-link-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px 10px 12px 50px;
    line-height: 1.15em;
    min-height: 60px;
    @include primary-gradient-bg;

    &.one-line {
      line-height: 35px;
    }
  }

  .box-link-video-thumb {
    height: 170px;
    width: 100%;
    background-size: cover;
    background-position: center center;
  }

  .box-link-overlay-video {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px 10px 12px 130px;
    line-height: 1.15em;
    background: #fff;
    color: $text-color;
    text-align: right;
    min-height: 80px;

    img {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .name {
      font-size: 12px;
      color: $brand-primary-light;
    }

    .title {
      padding: 3px 0;
      font-size: 18px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .date {
      font-size: 12px;
      color: #888;
    }

    &.dark {
      background: #013f70;
      text-align: left;
      padding: 12px 10px;
      color: #fff;


      .name {
        color: rgba(255, 255, 255, 0.5);
      }

      .title {
        font-size: 18px;
      }

      .date {
        color: $brand-primary-light;
      }
    }
  }

  .play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 60px;
    margin: -70px 0 0 -30px;
    padding-left: 5px;
    opacity: .9;
    fill: #fff;
    background: #ffb400;
    border-radius: 50%;
    text-align: center;
    line-height: 60px;
    @include box-shadow(0 3px 40px rgba(0, 0, 0, 0.6));
  }

  .box-link-overlay-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    @include border-radius(50%);
    @include box-shadow(0 0 3px rgba(0, 0, 0, 0.3));
    background: $brand-secondary;
    fill: $brand-primary;
    line-height: 30px;
    text-align: center;

    img {
      width: 15px;
      height: auto;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .box-link {
    height: auto;

    .play-btn {
      margin: -30px 0 0 -30px;
    }

    .box-link-video-thumb {
      height: auto;
      padding-bottom: 56.25%;
    }

    .box-link-overlay-video {
      position: static;
    }
  }
}

.box-phone-video {
  line-height: 1.15em;
  background: #013f70;
  text-align: left;
  padding: 12px 20px;
  color: #fff;

  img {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .name {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  }

  .title {
    padding: 3px 0;
    font-size: 18px;
  }

  .date {
    font-size: 12px;
    color: $brand-primary-light;
  }
}

.news-slider {
  position: relative;
  height: 250px;

  &.slick-dotted.slick-slider {
    margin-bottom: $default-margin;
  }

  .box-link {
    .box-link-overlay {
      padding-left: 12px;
      padding-left: 12px;
      padding-right: 90px;
    }
  }

  .slick-dots {
    position: absolute;
    right: 10px;
    bottom: 5px;
    width: auto;

    li {
      margin: 0 -2px;

      &  button:before {
        color: #fff;
        font-size: 12px;
        @include opacity(1);
      }

      &.slick-active button:before {
        @include opacity(1);
        color: $brand-secondary;
      }
    }
  }

  .slider-prev,
  .slider-next {
    position: absolute;
    top: 50%;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    background: $brand-secondary;
    @include border-radius(50%);
    text-align: center;
    z-index: 10;
    border: 0;
    fill: $brand-primary;
    line-height: 30px;
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  .slider-prev {
    left: 5px;

    svg {
      margin-right: 5px;
    }
  }

  .slider-next {
    right: 5px;

    svg {
      margin-left: 4px;
    }
  }
}

.map-info-container {
  position: relative;
  margin-bottom: $default-margin;

  .location-container {
    @include box-shadow(0 0 5px rgba(0,0,0,.6));

    .location-info-container {
      background: transparent;
    }

    @media (min-width: $screen-sm) {
      position: absolute;
      left: 10px;
      top: 20px;
      width: 300px;
    }
    z-index: 1;
  }

  .map-container {
    width: 100%;
    height: 60vh;
  }

  .plan-footer {
    background: rgba(0,87,154,.9);
  }

  @media (max-width: $screen-xs-max) {
    .location-container {
      width: 100%;
    }

    .map-container {
      position: static;
      height: 250px;
    }
  }
}

.compare-locations-link {
  position: absolute;
  right: 5px;
  top: 7px;
  color: #fff;
  z-index: 10;

  &:hover,
  &:focus {
    color: #fff;
  }
}

.location-info-container {
  background: #fff;
  position: relative;

  .tab-container {
    background: $brand-primary;
    padding: 10px 10px 0 10px;
    line-height: 25px;

    a,
    a:hover,
    a:focus {
      color: #fff;
    }
  }

  .fake-tab {
    color: $brand-primary;
    display: inline-block;
    padding: 0 10px;
    @include border-top-radius(5px);
    background: $brand-secondary;
  }

  .location-name {
    background: $brand-primary-lighter;
    padding: 6px 10px;

    svg {
      fill: transparent;
      stroke: $brand-primary-light;
    }
  }

  .toggle-favorite-location {
    svg {
      fill: transparent;
      stroke: $brand-primary-light;
    }

    &.active {
      svg {
        fill: $brand-primary-light;
        stroke: transparent;
      }
    }
  }

  .temp-info {
    position: relative;
    padding: 10px;
    font-size: 14px;

    .weather-icon-now {
      position: absolute;
      top: 10px;
      right: 10px;

      @media (max-width: $screen-xs-max) {
        top: 3px;
      }

      img {
        width: 70px;
        height: auto;
      }
    }

    .now {
      line-height: 1em;
      font-size: 34px;
    }

    .feels {
      margin-bottom: 5px;
    }

    .icon {
      width: 15px;
    }
  }

  .detail-tabs {
    border-bottom-color: $brand-primary-lighter;
    font-size: 12px;
    padding-left: 10px;

    & > li {
      & > a {
        color: $brand-primary;
        padding: 5px 10px;
      }
      &.active > a {
        background: $brand-primary-lighter;
        border-color: $brand-primary-lighter;
      }
    }
  }

  .tab-content {
    padding: 10px 6px;
  }

  .more-info-container {
    padding: 10px;
    line-height: 31px;
    color: $brand-primary;
    border-top: 1px solid $brand-primary-lighter;

    .btn {
      font-weight: bold;
    }
  }

  .temp-info-table {
    width: 100%;
    border-collapse: collapse;

    &.temp-info-table-hours {
      td {
        height: 30px;
      }
    }

    td {
      height: 40px;
      padding: 0 5px;
      vertical-align: middle;
    }

    .small {
      font-size: 12px;
    }

    .unit-icon {
      font-size: 12px;
    }

    img {
      width: 30px;
      height: auto;
    }
  }
}

.custom-select {
  padding-right: 35px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../img/custom-select.png');
  background-repeat: no-repeat;
  background-position: 95% center;

  &:active,
  &:focus {
    @include box-shadow(none);
  }
}

@media (max-width: $screen-xs-max) {
  .forecast-page-slider .location-info-2-container .detail-tabs {
    background: rgba(3,40,71,.9);
  }
}

.text-primary-lighter {
  color: #00b6fb;
}

.location-info-2-container {
  position: relative;

  .sun-icon {
    margin-top: -4px;
  }

  .temp-info {
    padding: 0 10px;
    position: relative;
    font-size: 14px;

    .icon {
      margin-top: -2px;
    }

    .weather-icon-now {
      img {
        width: 60px;
        height: auto;
      }
    }

    .now {
      margin: 5px 0 -3px;
      line-height: 1em;
      font-size: 34px;
    }

    .feels {
      margin-bottom: 5px;
    }

    .icon {
      width: 15px;
      fill: #00b6fb
    }

    .border-right {
      border-right: 1px solid $brand-primary-light;
    }
  }

  .detail-tabs {
    background: transparentize($brand-primary, 0.1);
    border: 0;
    padding-left: 10px;
    padding-top: 10px;

    & > li {
      & > a {
        color: #fff;
        padding: 5px 10px;

        &:active,
        &:focus,
        &:hover {
          color: #fff;
          background: $brand-primary-dark;
          border-color: $brand-primary-dark;
        }
      }
      &.active > a {
        color: $text-color;
        background: $brand-secondary;
        border-color: $brand-secondary;
      }
    }
  }

  .tab-content {
    background: transparentize(#032847, 0.1);
    padding: 10px 6px;
    height: 135px;
    color: #fff;

    a,
    a:hover,
    a:focus,
    a:active {
      color: #fff;
      text-decoration: none;
    }
  }

  .more-info-container {
    padding: 10px;
    line-height: 31px;
    color: $brand-primary;
    background: transparentize($brand-primary, 0.1);

    .btn,
    .btn:hover {
      font-weight: bold;
      color: #000;

      svg {
        width: 8px;
        height: 8px;
        vertical-align: baseline;
        margin-left: 3px;
      }

      @media (max-width: $screen-xs-max) {
        padding: 10px 15px;
      }
    }

    a,
    a:hover,
    a:focus {
      color: #fff;
    }
  }

  .temp-info-table {
    width: 100%;
    border-collapse: collapse;

    &.temp-info-table-hours {
      td {
        height: 30px;
      }
    }

    svg {
      margin-top: -2px;
    }

    td {
      height: 40px;
      padding: 0 5px;
      vertical-align: middle;
    }

    .small {
      font-size: 12px;
    }

    .unit-icon {
      font-size: 12px;
    }

    img {
      width: 30px;
      height: auto;
    }
  }
}

.location-info-forecast-container {
  .tab-content {
    height: auto;
  }

  .quote-container {
    padding-left: 70px;
    padding-right: 8px;
    position: relative;
  }

  .quote-author {
    position: absolute;
    left: 10px;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f5f5f5;
    background-size: cover;
    background-position: center center;
  }

  .quote {
    background: transparentize(#5a7a96, 0.5);
    color: #fff;
    position: relative;
    @include border-radius(5px);
    padding: 10px;

    &:before {
      @include caret('left', 9px, transparentize(#5a7a96, 0.5));
      position: absolute;
      right: 100%;
      top: 10px;
    }
  }
}

.light-white-hr {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.user-img-circle {
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center center;
  @include border-radius(50%);
}

.js-location-search-container {
  .search-result {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    @include border-radius($border-radius-base);
    list-style: none;
    margin: 5px 0 0 0;
    padding: 5px 0;
    z-index: 10;
    @include box-shadow(0 0 8px rgba(0, 0, 0, 0.3));
    max-height: 250px;
    overflow-y: auto;

    a {
      display: block;
      color: $text-color;
      padding: 5px 10px;
      border-bottom: 1px solid #eee;

      &:focus,
      &:hover {
        outline: none;
        text-decoration: none;
        background: $brand-primary-lighter;
      }
    }

    li:last-child a {
      border-bottom: 0;
    }

  }

  .my-locations {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    @include border-bottom-radius($border-radius-base);
    list-style: none;
    margin: 0;
    padding: 5px 0;
    z-index: 10;
    @include box-shadow(0 0 8px rgba(0, 0, 0, 0.3));
    max-height: 250px;
    overflow-y: auto;

    li {
      padding: 0 10px 0 30px;
      position: relative;
      border-bottom: 1px solid #eee;

      &:focus,
      &:hover {
        outline: none;
        background: $brand-primary-lighter;

        a {
          text-decoration: none;
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      &.my-locations-header {
        margin-top: -5px;
        padding: 10px;
        background: $brand-secondary;

        .toggle-favorite-location {
          color: #fff;
          border: 0;
          white-space: normal;
          margin-bottom: 10px;

          .remove {
            display: none;
          }

          &.active {
            display: none;
            background: $brand-primary;

            .save {
              display: none;
            }

            .remove {
              display: inline;
            }
          }
        }
      }
    }

    .remove-location {
      position: absolute;
      left: 2px;
      top: 50%;
      margin-top: -13px;
      width: 26px;
      height: 26px;
      padding: 0;
      border: 0;
      background: transparent;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      td {
        padding: 2px;
      }

      .weather {
        width: 40px;
        text-align: right;
      }

      .weather-icon img {
        width: 18px;
        height: auto;
      }

      .weather-temp {
        margin-top: -4px;
      }
    }

    a {
      display: block;
      color: $text-color;
      padding: 5px 10px 5px 0;
    }

    li:last-child a {
      border-bottom: 0;
    }
  }
}

.search-overlay-container {
  position: relative;

  &.search-my-locations .search-overlay {
    display: block;
  }
}

.search-overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.4);
}

.plan-your-route-form {
  .btn-secondary {
    color: $text-color;
  }
  .location-search-container input {
    @include placeholder(#aaa);
  }
  svg {
    margin-top: -2px;
  }
}

.location-search-container {
  background: $brand-secondary;
  padding: 10px;

  &.location-search {
    .search-overlay {
      display: block;
    }

    .input-container-input {
      z-index: 10;
    }
  }

  input {
    @include placeholder($text-color);

    &:focus {
      @include placeholder(#aaa);
    }
  }

  .search-container-remove {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    fill: #bfbfbf;
    background: transparent;
    border: 0;
    height: 31px;
    outline: none;

    &:hover {
      fill: #222;
    }
  }

  &.can-remove {
    .search-container-remove {
      display: inline-block;
    }
  }

  &.search-my-locations {
    .search-overlay {
      display: block;
    }

    .input-container-btn {
      z-index: 12;
    }

    .input-container .input-container-btn .btn {
      @include border-bottom-radius(0);
      background: $brand-secondary;
      border-color: $brand-secondary;

      svg {
        fill: $brand-primary;
      }

    }
  }

  .input-container {
    position: relative;
    padding-right: 38px;

    .input-container-input {
      position: relative;

      .icon-search {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }

    .input-container-btn {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -16px;

      .btn {
        padding-left: 0;
        padding-right: 0;
        width: 30px;
        height: 31px;
        line-height: 0;

        svg {
          fill: #fff;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.content-with-sidebar {
  position: relative;

  .content {
    margin-right: 320px;
  }

  .sidebar {
    position: absolute;
    right: 0;
    top: 0;
    width: 300px;
  }

  @media (max-width: $screen-xs-max) {
    .content {
      margin-right: 0;
    }

    .sidebar {
      display: none;
    }
  }
}

.radar-map-container {
  position: relative;
  height: 60vh;
  width: auto;

  .radar-map-overlay {
    position: absolute;
    top: 20px;
    left: 10px;
    z-index: 10;
    width: 300px;
  }

  @media (max-width: $screen-xs-max) {
    height: auto;

    .radar-map-overlay {
      position: static;
      width: auto;
    }

    .morecast-map-container {
      height: 50vh;
    }
  }
}

.bg-secondary-full {
  background: $brand-secondary;
}

.bg-secondary-light-full {
  background: $brand-secondary-light;
}

.bg-secondary-lighter-full {
  background: $brand-secondary-lighter;
}

.tabular-view-24_hours {
  .min-max-display {
    display: none;
  }
}

.forecast-view {
  position: relative;

  @media (max-width: $screen-xs-max) {
    background: #fff;
  }

  h4 {
    font-size: 15px;
  }

  .range-tabs {
    text-align: right;
    border: 0;
    margin-top: 8px;
    padding: 0 5px;

    & > li {
      float: none;
      display: inline-block;

      & > a {
        color: $text-color;
        background: rgba(0, 0, 0, 0.15);

        &:hover {
          background: rgba(0, 0, 0, 0.25);
          border-color: transparent;
        }
      }

      &.active > a {
        color: $text-color;
        background: #fff;
        border-color: #fff;;
      }
    }
  }
}

.min-temp-legend,
.max-temp-legend {
  display: inline-block;
  width: 15px;
  height: 2px;
  vertical-align: middle;
}

.min-temp-legend {
  background: rgba(69, 176, 230, 1);
}

.max-temp-legend {
  background: #ffb400;
}

.slider-container {
  position: relative;

  &.add-axes-width {
    padding-left: 35px;
    padding-right: 35px;
  }

  .scroll-btn {
    width: 35px;
    position: absolute;
    top: 0;
    bottom: 0;
    background: $brand-primary;
    border: 0;
    z-index: 5;
    padding: 0;
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    opacity: 1;
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    transition: opacity .3s;

    &:focus {
      outline: none;
    }

    .caret-left,
    .caret-right {
      margin-right: 0;
      margin-left: 0;
    }

    &.scroll-left {
      left: 0;
      @include border-left-radius(5px);
    }

    &.scroll-right {
      right: 0;
      @include border-right-radius(5px);
    }

    .inactive{
      opacity: .5;
    }
  }
}

.graph-controls .slider-inner {
  border-top: 1px solid $brand-primary;
  border-bottom: 1px solid $brand-primary;
  background: #fff;
}

.slider-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  @include drag-scroll;
  font-size: 0;

  .slider-element {
    font-size: $font-size-base;
    display: inline-block;
    width: 70px;

    &.active {
      background: #f6f6f6;
    }

    &.grey-box {
      padding: 12px 0;
      border-right: 1px solid #ddd;

      &:last-child {
        //border-right: 0;
      }
    }

    &.time-symbol {
      position: relative;
      height: 39px;

      .time {
        padding-left: 5px;
        text-align: left;
        font-size: 12px;
      }

      .day {
        padding-left: 5px;
        text-align: left;
        font-size: 10px;
        margin-top: -6px;
      }

      img {
        position: absolute;
        right: 5px;
        top: 8px;
        width: 22px;
        margin: 0 auto;
        height: auto;
        display: block;
      }
    }

    &.value-box {
      height: 60px;
      line-height: 60px;
      padding: 0;
      text-align: center;
    }
  }
}


.compare-headline {
  display: none;

  a {
    color: $text-color;
  }
}

.compare-view,
.forecast-view {
  .location-search-container .search-overlay {
    display: none !important;
  }
}

.tabular-container {
  margin-top: -1px;
  border: 1px solid #ddd;

  .tabular-text-container {
    position: relative;
    padding-left: 180px;

    .tabular-text {
      display: table-row;
      position: absolute;
      left: 0;
      top: 0;
      width: 180px;
      height: 60px;
      font-weight: bold;
      border-right: 1px solid #ddd;

      .tabular-text-inner {
        display: table-cell;
        width: 180px;
        height: 60px;
        padding: 0 10px;
        vertical-align: middle;
      }

      a {
        color: $text-color;
      }
    }

    .tabular-table {
      margin-left: -35px;
    }
  }
}

.is-compare {
  .compare-headline {
    display: block;
  }

  .tabular-container {
    .tabular-text-container {
      padding-left: 0;

      .tabular-text {
        display: none;
      }

      .tabular-table {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .compare-headline {
    display: block;
  }

  .tabular-container {
    .tabular-text-container {
      padding-left: 0;

      .tabular-text {
        display: none;
      }

      .tabular-table {
        margin-left: 0;
      }
    }
  }
}

.tabular-info {
  padding: $default-margin;
  background: #fef7e5;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.plan-your-route-container {
  background: #fff;

  @media (max-width: $screen-xs-max) {
    min-height: 0;

    .location-container {
      height: auto;
      overflow: inherit;
    }
  }

  .tab-container {
    background: rgba(0,87,154,.9);
  }

  .plan-your-route-tabs {
    list-style: none;
    margin: 0;
    padding: 0;
    @include clearfix;
    color: #fff;
    fill: #fff;

    & > li {
      width: 25%;
      float: left;
      display: block;

      label {
        display: block;
        margin: 0;
      }

      input {
        display: none;
      }

      input + span {
        display: block;
        text-align: center;
        @include border-top-radius($border-radius-base);
        padding: 3px 5px 1px;
        margin: 0 2px;

        &:hover {
          background: rgba(0, 0, 0, 0.2);
        }
      }

      input:checked + span {
        background: $brand-secondary;
        color: $brand-primary;
        fill: $brand-primary;
      }
    }
  }

  .route-collapse {
    display: block;
    padding: 5px 10px;
    color: $text-color;
    position: relative;

    &[aria-expanded="true"]:after {
      @include caret('top', 5px, $text-color);
    }

    &:after {
      @include caret('bottom', 5px, $text-color);
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -3px;
    }
  }

  .plan-route-input-container {
    background: #fff;
    @include border-radius($border-radius-base);
    padding: 5px 30px 5px 5px;
    position: relative;
  }

  .search-container {
    position: relative;

    .form-control {
      @include border-radius(0);
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);

      &:focus {
        @include box-shadow(none);
        outline: none;
      }
    }

    &:last-child .form-control {
      border-bottom: 0;
    }
  }

  .turn-locations {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 25px;
    background: transparent;
    border: 0;
    padding: 0;

    &:focus {
      outline: none;
    }

    svg {
      width: 15px;
      height: 20px;
    }
  }

  .add-location-link {
    color: $text-color;
    display: block;
    padding: 2px 0;
    line-height: 18px;
    margin-top: 8px;
  }
}

.icon-route-collapse {
  width: 15px;
  height: 15px;
}

.route-list {
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    border-bottom: 1px solid #999;
    display: block;
    padding: 10px 0;
  }

  .time {
    font-weight: bold;
  }

  .weather-icon,
  .weather-icon img {
    width: 40px;
    height: auto;
    display: inline-block;
    vertical-align: middle;
  }

  .temp-text {
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
  }
}

.route-result {
  display: none;
}

.btn-no-border-radius {
  @include border-radius(0);
}

.plus-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  width: 18px;
  height: 18px;
  @include border-radius(50%);
  background: $brand-primary;
  color: #fff;
  font-weight: bold;
  text-align: center;
  line-height: 18px;
  font-size: 22px;
}

.loading-overlay {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background: rgba(255, 255, 255, 0.5);

  .overlay-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40px;
    height: 40px;
    margin: -20px 0 0 -20px;
  }
}

.news-detail {
  h1 {
    margin: 0 0 $default-margin;
  }

  @media (max-width: $screen-xs-max) {
    h1 {
      font-size: 27px;
    }
  }
}

.tour-info-overlay {
  background: $brand-primary;
  @include border-radius($border-radius-base);
  @include box-shadow(0 0 7px rgba(0, 0, 0, 0.5));
  padding: 10px 30px 10px 10px;
  color: #fff;
  position: absolute;
  display: none;

  &.tour-info-step-1 {
    top: 20%;
    left: 10%;
    right: 10%;
    display: block;
  }

  &.tour-info-step-2 {
    top: 30px;
    left: 10px;
    width: 250px;

    &:before {
      @include caret('left', 10px, $brand-primary);
      position: absolute;
      right: 100%;
      top: 21px;
    }
  }

  .close-tour-x {
    position: absolute;
    right: 5px;
    top: 5px;
    background: transparent;
    width: 25px;
    height: 25px;
    padding: 0;
    text-align: center;
    line-height: 20px;
    font-weight: bold;
    border: 0;
  }

  h3 {
    margin: 0 0 10px;
  }

  p {
    margin: 0 0 10px;
  }
}

.block-modal-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
}

.add-location-btn {
  line-height: 51px;
  color: $text-color;

  &:hover {
    color: $text-color;
  }
}

.add-location-btn.add-location-btn-xs {
  position: absolute;
  right: 10px;
  top: -3px;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.video-modal {
  .modal-dialog {
    width: auto;
    max-width: 1000px;
  }

  .modal-body {
    padding: 0;
  }
}

.video-modal-link:focus,
.video-modal-link:active {
  outline: none;
}

.wp-caption {
  width: auto !important;
  max-width: 100%;

  .wp-caption-text {
    opacity: 0.8;
    font-size: 0.8em;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
}

.wp-content {
  img {
    max-width: 100% !important;
    width: auto !important;
    height: auto !important;
  }
}

.news-author-container {
  padding-left: 40px;

  .author-img {
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f5f5f5;
    background-size: cover;
    background-position: center center;
  }
}

.news-detail img.size-full {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.overlay-close-btn {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  background: #333;
  color: #fff;
  @include border-radius(50%);
  z-index: 10;
  font-size: 25px;
  font-weight: bold;
  border: 0;
  line-height: 30px;
  padding: 0;
  text-align: center;

  &:focus {
    outline: none;
  }
}

.modal-backdrop.in {
  @include opacity(0.7);
}

.map-overlay-index.is-fullscreen {
  .location-container {
    @include box-shadow(0 0 5px rgba(0, 0, 0, 0.6));
    position: absolute;
    top: 20px;
    left: 10px;
    z-index: 10;
  }
  .map-container {
    left: 0;
  }
}

.plan-your-route-container.is-fullscreen {
  .map-container {
    height: 100vh;
  }
}

.radar-map-overlay {
  @include box-shadow(0 0 5px rgba(0, 0, 0, 0.6));
}

.weather-globe-iframe-container {
  position: relative;
  height: 100%;
  width: 100%;
}

@media (max-width: $screen-xs-max) {
  .weather-globe-container {
    .weather-globe-iframe {
      margin-top: 0;
      height: 60vh;
    }

    .location-name,
    .more-info-container,
    .temp-info {
      display: none;
    }
  }

  .is-fullscreen {
    .weather-globe-iframe-container {
      position: absolute;
      top: 50px;
      bottom: 0;
      left: 0;
      height: auto;
    }

    .weather-globe-iframe {
      height: 100%;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .plan-route-button {
    padding: 10px 12px;
    font-size: 1.2em;
  }
}

@media (max-width: $screen-xs-max) {
  .morecast-map.disable-pan {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    .ol-overlaycontainer-stopevent {
      .ol-attribution,
      .ol-control {
        z-index: 1;
      }
    }
  }
}

.pagination-links {
  border-top: 1px solid #ddd;
  margin: 10px -20px 0;
  padding: 20px 20px 0;

  a {
    color: $brand-primary;
  }
}

.news-ticker {
  background: #d91503;
  color: #fff;
  padding: 10px 15px;
  white-space: nowrap;
  display: block;

  &:hover {
    color: #fff;
    background: darken(#d91503, 5%);
    text-decoration: none;
  }
}

.remove-padding-container {
  @media (min-width: $screen-sm) {
    margin-left: -$default-margin;
    margin-right: -$default-margin;
  }

  @media (min-width: $screen-md) {
    margin-right: 0;
  }
}

.main-slider-container {
  position: relative;

  .bg-fill {
    background-size: cover;
    background-position: left center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    @media (max-width: $screen-xs-max) {
      background-position: center center;
    }
  }

  .main-slider {
    height: 340px;
    margin-bottom: $default-margin;

    & > div {
      display: none;
      height: 340px;

      &:first-child {
        display: block;
      }
    }

    &.slick-initialized > div {
      display: block;
    }
  }

  .nav-holder {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .arrow-holder-left,
  .arrow-holder-right,
  .dots-holder {
    display: inline-block;
    vertical-align: middle;
  }

  .slick-dots {
    margin-top: -3px;
    position: static;
    width: auto;

    li {
      margin: 0 -2px;

      button:before {
        color: #fff;
        font-size: 10px;
        @include opacity(1);
      }

      &.slick-active button:before {
        @include opacity(1);
        color: $brand-secondary;
      }
    }
  }

  .slider-prev,
  .slider-next {
    display: inline-block;
    vertical-align: middle;
    position: static;
    width: 25px;
    height: 30px;
    background: transparent;
    text-align: center;
    z-index: 10;
    border: 0;
    fill: #fff;
    line-height: 30px;
    padding: 0;

    .arrow-icon {
      width: 10px;
      height: 10px;
    }

    &:focus {
      outline: none;
    }
  }

  .main-slider-content {
    display: block;
    position: relative;
    background-position: top right;
    background-repeat: no-repeat;
    width: 100%;
    height: 340px;

    .main-slider-text {
      position: absolute;
      right: 25px;
      bottom: 25px;
      color: #fff;
      text-align: right;
      width: 40%;

      h3 {
        font-size: 24px;
        font-weight: bold;
      }
    }
  }

  &.forecast-page-slider {
    .main-slider,
    .main-slider>div {
      height: 380px;
    }

    .main-slider-content {
      height: 380px;
      background-position: top left;
    }
  }

  .main-slider-btn {
    display: inline-block;
    @include border-radius(5px);
    font-weight: bold;
    border: 0;
    padding: 10px 15px;
    color: #111;
    background: $brand-secondary;
    white-space: nowrap;

    .arrow {
      width: 8px;
      height: 8px;
      margin-left: 3px;
      vertical-align: baseline;
    }

    &:hover {
      background: darken($brand-secondary, 2%);
      text-decoration: none;
    }
  }

  .main-slider-location {
    position: absolute;
    left: $default-margin;
    bottom: 0;
    width: 300px;
  }

  .main-slider-location-forecast {
    width: 620px;
  }

  @media (max-width: $screen-xs-max) {
    .slider-prev,
    .slider-next {
      display: none !important;
    }

    .main-slider {
      height: 220px;
      margin-bottom: 0;

      &>div {
        height: 220px;
      }

      .main-slider-content {
        height: 220px;
        background-position: center center;
        background-size: cover;

        .main-slider-text {
          width: 60%;
          left: 25px;
          right: auto;
          text-align: left;

          h3 {
            font-size: 20px;
          }
        }
      }
    }

    .main-slider-location {
      //margin-top: -$default-margin;
      margin-bottom: $default-margin;
      position: static;
      width: 100%;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .forecast-view .main-slider-container .main-slider-location {
    margin-bottom: 0;
  }
}

.forecast-switch-btn {
  fill: #fff;
  color: #fff;
  position: absolute;
  right: 8px;
  top: 15px;

  &:hover {
    color: #fff;
  }

  svg {
    margin-right: 5px;
    margin-top: -2px;
    vertical-align: middle;
  }
}

.map-index-container {
  height: 250px;
  margin-bottom: $default-margin;
}

@-webkit-keyframes marquee {
  0%   { text-indent: 100%; }
  100% { text-indent: -100%; }
}
@keyframes marquee {
  0%   { text-indent: 100%; }
  100% { text-indent: -100%; }
}
.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  animation: marquee 20s linear infinite;
  -webkit-animation: marquee 20s linear infinite;
}

.video-overview-box {
  height: 170px;

  .video-img {
    background-size: cover;
    background-position: center center;
    position: relative;
    width: 100%;
    height: 50px;

    &:hover {
      opacity: 0.9;
    }

    .play-btn {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 60px;
      height: 60px;
      margin: -30px 0 0 -30px;
      padding-left: 5px;
      opacity: 0.9;
      fill: #fff;
      background: $brand-secondary;
      @include border-radius(50%);
      text-align: center;
      line-height: 60px;
      @include box-shadow(0 3px 40px rgba(0, 0, 0, 0.6));

      &.play-btn-small {
        width: 30px;
        height: 30px;
        margin: -15px 0 0 -15px;
        line-height: 30px;

        svg {

          width: 10px;
          height: 10px;
        }
      }
    }
  }

  .video-overview-info {
    display: block;
    padding: 5px;
    @include border-radius(5px);

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    &:first-child {
      margin-top: -5px;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .video-overview-title {
    font-size: 14px;
    color: #fff;
    font-weight: normal;
    margin: 5px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .video-overview-time {
    font-size: 14px;
    color: #00b9ff;
  }

  .video-big {
    width: 100%;
    height: 170px;
  }
}

.graph-controls-container {
  background: $brand-secondary;
  padding: 10px 10px;

  .graph-controls {
    margin-bottom: 0;
  }
}

.forecast-tabular-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;

  .header-short {
    display: none;
  }

  @media (max-width: $screen-xs-max) {
    .header-long {
      display: none;
    }

    .header-short {
      display: block;
    }
  }

  thead tr {
    th {
      vertical-align: top;
      background: $brand-secondary;
      font-weight: normal;
      line-height: 1.1em;
      padding: 8px 10px;

      .sub-title {
        opacity: 0.5;
        font-size: 12px;
        line-height: 1.2em;
        padding-top: 2px;
      }
    }
  }

  .border-tr {
    td {
      border-bottom: 1px solid #cfdce6;
    }
  }

  td {
    padding: 8px 10px;
    vertical-align: middle;
    font-size: 14px;
    white-space: nowrap;

    .day {
      font-size: 0.8em;
    }

    img {
      width: 40px;
      height: auto;
    }

    .forecast-tabular-main {
      font-size: 20px;

      .icon {
        margin-top: -2px;
      }
    }

    .forecast-tabular-append {
      margin-left: 6px;
      font-size: 14px;
      display: inline-block;
    }
  }

  @media (max-width: $screen-sm-max) {
    thead tr {
      th {
        padding: 8px 5px;
      }
    }

    td {
      padding: 8px 5px;

      img {
        width: 25px;
      }

      .forecast-tabular-main {
        font-size: 16px;
      }

      .forecast-tabular-append {
        display: block;
        margin-left: 0;
      }
    }
  }
}

.table-two-row {
  display: none;
}

.compare-view {
  @media (max-width: $screen-sm-max) {
    .table-one-row {
      display: none;
    }
    .table-two-row {
      display: block;
    }
  }

  .left-row-compare .location-info-container {
    .forecast-tabular-table tr {
      th:last-child,
      td:last-child {
        border-right: 1px solid #111;
      }
    }
  }
  .forecast-tabular-table {
    .header-long {
      display: none;
    }

    .header-short {
      display: block;
    }

    td {
      img {
        width: 25px;
      }

      .forecast-tabular-main {
        font-size: 16px;
      }
      .forecast-tabular-append {
        display: block;
        margin-left: 0;

        &.append-padding {
          padding-left: 13px;
        }
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    .location-info-2-container .temp-info .left-col {
      border: 0;
      width: 100%;
    }
    .right-col {
      display: none;
    }
  }

}

.forecast-fixed-content {
  background: #fff;
}

.forecast-header-fixed,
.forecast-sub-header-fixed {
  max-width: 620px;
  z-index: 5;
  position: relative;
}

.forecast-header-fixed {
  @media (max-width: $screen-xs-max) {
    max-width: none;
    width: 100% !important;
  }
}

.forecast-view {
  .forecast-header-fixed,
  .forecast-sub-header-fixed {
    @media (max-width: $screen-xs-max) {
      left: 0 !important;
      max-width: none;
      width: 100% !important;
    }
  }
}

.compare-view {
  .forecast-header-fixed {
    @media (max-width: $screen-xs-max) {
      left: 0 !important;
      max-width: none;
      width: 100% !important;
    }
  }
}

.forecast-sidebar {
  width: 300px;
  position: relative;

  @media (max-width: $screen-xs-max) {
    position: static !important;
    max-width: none;
    width: 100% !important;
  }
}

.compare-view {
  .community-container {
    margin-top: -20px;
  }

  .main-slider-location {
    width: calc(100% - 340px);

    @media (max-width: $screen-sm-max) {
      width: calc(100% - 360px);
    }

    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
  }

  @media (min-width: $screen-sm) {
    .main-slider-container .main-slider-location {
      bottom: 103px;
    }

    .community-container {
      margin-top: -123px;
    }

    .community-sidebar {
      top: 123px;
    }
  }
}

.js-search-location {
  padding-right: 25px;
}

.video-page-header {
  background: url('../img/video-page-bg.jpg');
  background-size: cover;
  background-position: center center;
  color: #fff;
  padding-top: 20px;

  @media (min-width: $screen-sm) {
    margin-left: -20px;
    padding-left: 20px;
  }

  @media (max-width: $screen-xs-max) {
    padding-top: 0;
  }

  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    margin-right: -20px;
  }

  .category {
    color: #fff;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 3px;
  }

  h1 {
    font-size: 28px;
    font-weight: normal;
    margin: 3px 0;
  }

  .video-page-header-top {
    min-height: 350px;

    h2 {
      font-size: 20px;
      font-weight: normal;
      margin: 20px 0 5px;
    }
  }

  .video-page-header-bottom {
    margin-top: -20px;
    padding-top: 40px;
    padding-bottom: 20px;
    background: #abbac6;
    color: $text-color;

    @media (min-width: $screen-sm) {
      margin-left: -20px;
      padding-left: 20px;
    }

    @media (max-width: $screen-xs-max) {
      margin-top: 0;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }


  .video-overview-info {
    display: block;
    padding: 5px;
    border-radius: 5px;
    margin: 0 20px 5px -5px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    &.active {
      background: rgba(0, 0, 0, 0.2);
    }

    .video-img {
      background-size: cover;
      background-position: center center;
      position: relative;
      width: 100%;
      height: 50px;

      .play-btn {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 60px;
        height: 60px;
        margin: -30px 0 0 -30px;
        padding-left: 5px;
        opacity: 0.9;
        fill: #fff;
        background: $brand-secondary;
        @include border-radius(50%);
        text-align: center;
        line-height: 60px;
        @include box-shadow(0 3px 40px rgba(0, 0, 0, 0.6));

        &.play-btn-small {
          width: 30px;
          height: 30px;
          margin: -15px 0 0 -15px;
          line-height: 30px;

          svg {
            width: 10px;
            height: 10px;
          }
        }
      }
    }

    .video-overview-title {
      font-size: 14px;
      color: #fff;
      font-weight: normal;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .video-overview-time {
      font-size: 14px;
      color: #00b9ff;
    }
  }
}

.default-shadow {
  @include box-shadow(0 5px 20px rgba(0, 0, 0, 0.4));
}

.video-js .vjs-big-play-button {
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  padding-left: 5px;
  opacity: .9;
  fill: #fff;
  background: $brand-secondary;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  @include box-shadow(0 3px 40px rgba(0, 0, 0, 0.6));
  border: 0;
}
.video-js:hover .vjs-big-play-button {
  background: $brand-secondary;
}

.bg-darkener {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 7;
  background: #143349;
  opacity: 0.8;

  @media (min-width: $screen-sm) {
    display: none !important;
  }
}

.video-category-select {
  @media (min-width: $screen-sm) {
    border-left: 1px solid #70899c;
  }

  .form-control {
    border-color: #fff;
  }
}

.ad-info {
  position: absolute;
  right: 0;
  bottom: 100%;
  color:#fff;
  font-size: 10px;
  padding: 0 10px 0 20px;
  height: 15px;
  line-height: 15px;
  overflow: hidden;

  span {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    left: 15px;
    top: 0;
    bottom: 0;
    background: #5f5f5f;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    @include caret('left', 15px, #5f5f5f);
  }
}

.cookie-banner {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $brand-secondary;
  z-index: 9999;
  color: #000;
  padding: 15px 0;

  .cookie-content {
    max-width: $container-desktop;
    padding: 0 10px;
  }

  .cookie-btn {
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    background: $text-color;
    color: #fff;
    border-radius: 3px;
    padding: 5px 10px;
    margin: 0 5px 5px;

    &:hover {
      color: #fff;
      background: #555;
    }
  }

  .cookie-link {
    color: $text-color;
    text-decoration: underline;

    &:hover {
      color: #555;
    }
  }
}


.sticky-video {
  .text {
    display: none;
  }

  &.is-sticky {
    position: fixed;
    left: 0;
    right: 0;
    top: 58px;
    background: #abbac6;
    padding: 7px;
    @include clearfix;
    @include box-shadow(0 5px 5px rgba(0,0,0,.3));
    z-index: 10;

    .default-shadow {
      @include box-shadow(0 2px 5px rgba(0,0,0,.3));
    }

    .vjs-big-play-button,
    .vjs-control-bar,
    .vjs-error-display,
    .vjs-caption-settings {
      display: none;
    }

    .video {
      float: left;
      width: 30%;
    }

    .text {
      display: block;
      float: left;
      width: 70%;
      padding: 0 50px 0 10px;
      position: relative;

      .category {
        margin: 0;
      }

      .title {
        color: #000;
      }
    }

    .button-up {
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      background: #c3cfd9;
      color: #687987;
      @include border-radius(50%);

      &:after {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -3px 0 0 -5px;
        @include caret('top', 5px, #687987);
      }
    }
  }

}

.category-sticky {
  &.is-sticky {
    position: fixed;
    left: 0;
    right: 0;
    top: 58px;
    background: #abbac6;
    padding: 7px;
    @include clearfix;
    @include box-shadow(0 5px 5px rgba(0,0,0,.3));
    z-index: 11;
    border-top: 1px solid #cbd7e1;

    .sticky-hidden {
      display: none;
    }
  }
}

table {
  .tabular-col-1 {
    width: 12%;
  }
  .tabular-col-2 {
    width: 12%;
  }
  .tabular-col-3 {
    width: 27%;
  }
  .tabular-col-4 {
    width: 27%;
  }
  .tabular-col-5 {
    width: 27%;
  }

  .tabular-view-24_hours & {
    .tabular-col-1 {
      width: 15%;
    }
    .tabular-col-2 {
      width: 15%;
    }
    .tabular-col-3 {
      width: 20%;
    }
  }

  @media (max-width: $screen-xs-max) {
    &,
    &.tabular-view-24_hours {
      .tabular-col-1 {
        width: 20%;
      }
      .tabular-col-2 {
        width: 15%;
      }
      .tabular-col-3 {
        width: 20%;
      }
      .tabular-col-4 {
        width: 25%;
      }
      .tabular-col-5 {
        width: 25%;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .btn-block-xs {
    display: block;
  }
}

.video-social-icon {
  width: 24px;
  height: 24px;
}

.container .video-page .play-btn-on-hover,
.container .play-btn-on-hover {
  .play-btn {
    transition: opacity 0.2s;
    @include opacity(0);

    @media(max-width: $screen-xs-max) {
      @include opacity(0.9);
    }
  }
  &:hover .play-btn {
    @include opacity(0.9);
  }
}
