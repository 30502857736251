.sprite {
  display: inline-block;
}

.label-as-badge {
  @include border-radius(1em);
}

.navbar-brand-text {
  padding-top: 5px;
  padding-bottom: 5px;

  img {
    display: inline-block;
    vertical-align: middle;
  }
}

.list-inline-separate {
  display: block;
  padding: 0;

  li {
    display: inline-block;

    &:after {
      position: relative;
      top: -1px;
      margin: 0 8px 0 10px;
      content: '|';
    }

    &:last-child:after {
      display: none;
    }
  }
}

.hover-opacity {
  @include opacity(1);
  @include transition(opacity .15s ease-in-out);

  &:hover {
    @include opacity(.7);
  }
}

.parallax {
  padding: 80px 0;
  color: #fff;
  background: transparent no-repeat center center fixed;
  background-size: cover;
}

.bg-noise {
  position: relative;

  &:after {
    @include opacity(.18);

    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyBAMAAADsEZWCAAAAGFBMVEX///9JSUmSkpJtbW0kJCQAAAC2trbb29uzM0P0AAAACHRSTlMtLS0tLS0tLf6lJO0AAAPsSURBVDjLDc+5omtQFADQbW7NWnNaRwwtriMtQrTmtAjJ77/3B2vBL2Fv3NPoqNvKPj5TMzwt0Rg51YbN69hIFmdW9F67gb1vc2ei145X0LQn5jzCZrk9x497EoSPJDkFIQF4VV1730LViXTSTQ/JS89KuegJnjWavtzhUcZNiq6bUjzJg3aSQwXUd5MiCNrfw1NxKudsaJDOa9ISEFWHQNTYreFWx1J+NseVrBTKI2DF7/xjUu+ObmIyxvNPyqdjdlIJ9Kr+6C+qL8ui+P1dYsRySeHylgSYzOWP55aUup9wzuTPNzdcDdwO3kbrDdo2vP1PHT5En04w/TWT4Ihy7TaXo8rrFaJDpxPjvzmGlIB4G3OTqN01tfJHx1XxoYYwVNEClCkSwmcSJ1QinZfM3JWMR6vrGaDlasqzM0I8HQlJDT52j4RG/B0wcW6MIpLud/+ciWJ0MXFku97XsPoJsn+qjvfPA/d97PozfacYmgRCsFnPF+8MNkuOfXePzzVzwLMyKO2k++neuls18Hq7l7KvM1DjDv6adTJqgi2cYT7fmSq2rrqchxSDH5CoLEClIBD3cRhSnLbj2d12EFelDzfZQ6M3hKltsilbDq1qExCfCq2ilYFR1FFVW0O1K+qXskxwuBhXd9sZFGOTJUr6bKy+uatcwvYa0kk8kyvvrW3OjwVjVNnWfoFJ9d5bYp6Tgql4AaNWKs7Ql4cLH7J/3jIeUY0Wp+ePqFB45/RGJMBd5mctovSWMK8B1Rgs9RLeFaxQUpKWeTy2KvShnp74C9sa17ZmQYixV7P/w/SKkEUdehzEVqzyARDN6c/iJWfiszDMpXyahda/lbKByw979aA+9JXHkXHf+T8oiGiqCphrrb00z5xIk0fDxYuGL4waazEAf63lMXn/4AU7csuWtl/Sa/FoHnBtyIHI98VW2DWZrL6U1PF99GTojkIO+IrOtZWxdK9q7JReTZXNITa7a7mIjM18rfN+jFOUKPD7KYDfYclv0QjVO/58FRht7O/tWzJ5iJx9fL0Vfktq1MP6oN1c+kL2jeB4G9vG2TuDi1sY+XfxoqANcjcFPzboDXSPeMbJrXFLdhdKEuXaFy5G37XOcminUdnQZY6X5Q9d86bgJriP7OHLoRZyLjcm3Zdym9IcPaD3SaAObunNbMxQlf8lhQI7Lh4QJIEaDl12nY58ZgtotBrAchcQHFLzzZfzj0PyjQzE6S+c/3OqJYAsQNVLwO/gnVIlsX0zdKTvJ6ke0Dm2YjW8ZK+Qs+FLdb9n9NTEcwC+KweGzH6mRvUsqltRJgOBwY0Jzk+8n/25Gm+N1S0E1IMh8CuS8n+TDhbIQYK8+wAAAABJRU5ErkJggg==);
  }
}

.bg-dots {
  position: relative;

  &:after {
    @include opacity(.3);

    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAEAQMAAACTPww9AAAABlBMVEUAAAAAAAClZ7nPAAAAAXRSTlMAQObYZgAAAA1JREFUCNdjWMDAAMIABkgBQY5GT1IAAAAASUVORK5CYII=);
  }
}

.gradient-bg {
  @include gradient-directional-free(135deg, #000, darken($brand-primary, 50%) 15%, darken($brand-primary, 30%) 40%, $brand-primary 70%, lighten($brand-primary, 20%) 95%, lighten($brand-primary, 30%));

  color: #fff;
}

.table-display {
  display: table;
  width: 100%;

  & > * {
    display: table-cell;
  }
}

@media (max-width: $screen-md-max) {
  [data-equalizer-disable='md'] [data-equalizer-element] {
    height: auto !important;
  }
}

@media (max-width: $screen-sm-max) {
  [data-equalizer-disable='sm'] [data-equalizer-element] {
    height: auto !important;
  }
}

@media (max-width: $screen-xs-max) {
  [data-equalizer-disable='xs'] [data-equalizer-element] {
    height: auto !important;
  }
}
