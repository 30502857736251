.svg-icons-bicycle-dims {
	width: 22.31px;
	height: 13.17px;
}

.svg-icons-bike-dims {
	width: 21.84px;
	height: 13.27px;
}

.svg-icons-camera-dims {
	width: 30px;
	height: 24.26px;
}

.svg-icons-car-dims {
	width: 31.87px;
	height: 11.13px;
}

.svg-icons-clock-dims {
	width: 11.1px;
	height: 11.1px;
}

.svg-icons-close-dims {
	width: 10px;
	height: 10px;
}

.svg-icons-close-2-dims {
	width: 10px;
	height: 10px;
}

.svg-icons-cloud-dims {
	width: 22px;
	height: 15px;
}

.svg-icons-comments-dims {
	width: 28.01px;
	height: 30px;
}

.svg-icons-crown-dims {
	width: 20px;
	height: 15px;
}

.svg-icons-fullscreen-dims {
	width: 15px;
	height: 15px;
}

.svg-icons-fullscreen-end-dims {
	width: 15px;
	height: 15px;
}

.svg-icons-gear-dims {
	width: 15px;
	height: 15px;
}

.svg-icons-globe-dims {
	width: 15px;
	height: 15px;
}

.svg-icons-graph-dims {
	width: 15px;
	height: 15px;
}

.svg-icons-heart-dims {
	width: 20px;
	height: 18.03px;
}

.svg-icons-heart-outline-dims {
	width: 20px;
	height: 18.03px;
}

.svg-icons-lightning-dims {
	width: 4.36px;
	height: 14.97px;
}

.svg-icons-loading-dims {
	width: 25px;
	height: 25px;
}

.svg-icons-map-pin-active-dims {
	width: 50px;
	height: 68px;
}

.svg-icons-more-dims {
	width: 20px;
	height: 3.55px;
}

.svg-icons-pause-dims {
	width: 15.01px;
	height: 15px;
}

.svg-icons-pedestrian-dims {
	width: 10.26px;
	height: 16.64px;
}

.svg-icons-pen-dims {
	width: 12.53px;
	height: 17.44px;
}

.svg-icons-pin-dims {
	width: 10.34px;
	height: 15px;
}

.svg-icons-play-dims {
	width: 15px;
	height: 15px;
}

.svg-icons-prec-dims {
	width: 7.63px;
	height: 12px;
}

.svg-icons-search-dims {
	width: 14.04px;
	height: 15px;
}

.svg-icons-share-dims {
	width: 14.44px;
	height: 18px;
}

.svg-icons-snow-dims {
	width: 13.07px;
	height: 11.42px;
}

.svg-icons-social-facebook-dims {
	width: 30px;
	height: 30px;
}

.svg-icons-social-facebook-icon-dims {
	width: 12.98px;
	height: 25px;
}

.svg-icons-social-google-plus-dims {
	width: 30px;
	height: 30px;
}

.svg-icons-social-google-plus-icon-dims {
	width: 39.29px;
	height: 25px;
}

.svg-icons-social-instagram-dims {
	width: 30px;
	height: 30px;
}

.svg-icons-social-twitter-dims {
	width: 30px;
	height: 30px;
}

.svg-icons-social-twitter-icon-dims {
	width: 30.77px;
	height: 25px;
}

.svg-icons-social-youtube-dims {
	width: 30px;
	height: 30px;
}

.svg-icons-star-dims {
	width: 15px;
	height: 15px;
}

.svg-icons-sunrise-dims {
	width: 19px;
	height: 12.98px;
}

.svg-icons-sunset-dims {
	width: 19px;
	height: 12.97px;
}

.svg-icons-table-view-dims {
	width: 11.95px;
	height: 15.49px;
}

.svg-icons-turn-locations-dims {
	width: 13.1px;
	height: 31.16px;
}

.svg-icons-webcam-dims {
	width: 16px;
	height: 19px;
}

.svg-icons-wind-dims {
	width: 8.3px;
	height: 12.8px;
}

.svg-icons-wind-2-dims {
	width: 8.3px;
	height: 12.8px;
}

.svg-icons-wind-rotated-dims {
	width: 12px;
	height: 12px;
}

