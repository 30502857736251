.row {
  &.no-col-padding {
    margin-right: 0;
    margin-left: 0;

    & > [class^='col-'],
    & > [class*=' col-'] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  [class^='col-'],
  [class*=' col-'] {
    &.centered {
      float: none;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &.v-align {
    display: table;
    width: 100%;
    margin-right: 0;
    margin-left: 0;

    & > [class^='col-'],
    & > [class*=' col-'] {
      vertical-align: middle;

      &.top {
        vertical-align: top;
      }

      &.bottom {
        vertical-align: bottom;
      }
    }

    @media (min-width: $screen-lg) {
      & > [class^='col-lg-'],
      & > [class*=' col-lg-'] {
        display: table-cell;
        float: none;
      }
    }

    @media (min-width: $screen-md) {
      & > [class^='col-md-'],
      & > [class*=' col-md-'] {
        display: table-cell;
        float: none;
      }
    }

    @media (min-width: $screen-sm) {
      & > [class^='col-sm-'],
      & > [class*=' col-sm-'] {
        display: table-cell;
        float: none;
      }
    }

    & > [class^='col-xs-'],
    & > [class*=' col-xs-'] {
      display: table-cell;
      float: none;
    }
  }
}
