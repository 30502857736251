html {
  height: 100%;
}

.btn-scroll-top {
  @include opacity(.5);
  @include border-radius(5px);

  line-height: 20px;
  position: fixed;
  z-index: 50;
  right: 10px;
  bottom: 10px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  border: none;
  background: #000;

  &:hover,
  &:focus {
    @include opacity(.8);

    outline: none;
  }
}

@media (max-width: $screen-md-max) {
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
  .text-md-justify {
    text-align: justify;
  }
}

@media (max-width: $screen-sm-max) {
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
  .text-sm-justify {
    text-align: justify;
  }
}

@media (max-width: $screen-xs-max) {
  .text-xs-left {
    text-align: left;
  }
  .text-xs-right {
    text-align: right;
  }
  .text-xs-center {
    text-align: center;
  }
  .text-xs-justify {
    text-align: justify;
  }
}
