$defaultStrokeWidth: 2;

.pando-chart{
  min-height: 160px;
  margin-left: 35px;
  position:relative;
  display:block;
  font-size: $font-size-base;
}

.pando-chart svg{
  display:inline-block;
  background:#FFF;
}

.psc-grid-bg{
  fill: transparent;
  stroke: transparent;
}

.psc-grid-line{
  stroke:#DDDDDD;
}

.psc-axis-fill{
  fill:#FFF;
}

.psc-axis-line{
  stroke:#DDDDDD;
}

.psc-axis-label-line{
  stroke:#DDDDDD;
}

.psc-axis-label-text{
  stroke:#DDDDDD;
}

.psc-path path{
  stroke: rgb(50,50,50);
  fill: transparent;
}

.psc-point-img-path-missing{
  fill: #F00 !important;
  stroke: #FF0 !important;
}

.below-treshold{
  fill: #0F0;
  stroke: #0F0;
}
.above-treshold{
  fill: #F00;
  stroke: #F00;
}

/* Custom */
.chart-container{
  position:relative;
  margin: 10px auto 30px;
}
.pando-axis{
  position:absolute;
  top:0;
  left:0;
}

.psc-axis-label-text,
.psc-label text {
  stroke: transparent;
  fill: rgb(55, 55, 55);
}
.psc-label text{
  font-size: $font-size-base;
}
.psc-axis-label-text{
  font-size: .8em;
}

.graph-controls{
  margin: 0 0 30px;
}

.psc-grid-bg.active{
  fill: #f6f6f6;
}

#graph-temp-marker .psc-point { fill: #ffb400; }
#graph-temp path { stroke: #ffb400; stroke-width: $defaultStrokeWidth; }
#area-temp { fill: rgba(252, 184, 41, 0.2); }

#graph-temp-min-marker .psc-point { fill: rgba(69, 176, 230, 1); }
#graph-temp-min path { stroke: rgba(69, 176, 230, 1); stroke-width: $defaultStrokeWidth; }

.graph-prec-mm {
  .psc-bar-line{  stroke: rgba(69, 176, 230, 1);  stroke-width: $defaultStrokeWidth;}
  .psc-bar {      fill:   rgba(69, 176, 230, 0.2);}
}

#graph-wind path,

.graph-prec-perc .psc-path path {               stroke: #45b0e6; stroke-width: $defaultStrokeWidth;}
.graph-prec-perc .psc-area  { fill: rgba(69, 176, 230, 0.2); }
#graph-prec-perc-marker .psc-point{   fill: #45b0e6;}
.graph-wind .psc-area  { fill: rgba(69, 176, 230, 0.2); }

#graph-wind-gust path{stroke:transparent;}

#graph-sunshine{
  .psc-bar{       fill:   rgba(252, 184, 41, 0.2);}
  .psc-bar-line{  stroke: rgba(252, 184, 41, 1); stroke-width: $defaultStrokeWidth;}
}

#graph-uv{
  .psc-uv-above{
    &.psc-bar{       fill:   rgba(241, 94, 50, 0.2)}
    &.psc-bar-line{  stroke: rgba(241, 94, 50, 1); stroke-width: $defaultStrokeWidth;}
  }
  .psc-uv-below{
    &.psc-bar{       fill:   rgba(252, 184, 41, 0.2);}
    &.psc-bar-line{  stroke: rgba(252, 184, 41, 1); stroke-width: $defaultStrokeWidth;}
  }
}

#graph-clouds{
  .psc-bar{       fill:   rgba(5, 80, 138, 0.2);}
  .psc-bar-line{  stroke: rgba(5, 80, 138, 1); stroke-width: $defaultStrokeWidth;}
}

#area-humidity,
#area-pressure{       fill: rgba(5,80,138,.2);}

#graph-humidity-marker,
#graph-pressure-marker .psc-point {  fill:   rgba(5, 80, 138, 1); }

#graph-humidity path,
#graph-pressure path {        stroke: rgba(5, 80, 138, 1); stroke-width: $defaultStrokeWidth;}

.graph-legend {
  text-align: right;
  list-style: none;
  margin: -20px 0 20px;

  li {
    display: inline-block;
    margin-left: 10px;
  }

  .wind {
    display: inline-block;
    width: 20px;
    height: 2px;
    vertical-align: middle;
    background: #45b0e6;
  }

  .wind-gust {
    display: inline-block;
    width: 20px;
    height: 10px;
    vertical-align: middle;
    background: rgba(69, 176, 230, 0.2);
  }

  .wind-dir {
    fill: rgba(5, 80, 138, 1);
  }
}
