.progress-sm {
  height: 10px;
}

.progress-lg {
  &,
  & .progress-bar {
    height: 30px;
  }
}
