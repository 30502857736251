 .js-append-element {
  position: static;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;

  .navbar-header {
    background: $brand-primary;
    transform: translate3d(0, 0, 0);
  }

  &.is-fixed {
    position: fixed !important;
  }
}

.header-container {
  background: $brand-primary;
  margin-left: -$container-padding;

  @media (max-width: $grid-float-breakpoint-max) {
    margin-left: 0;
  }

  .navbar {
    border: 0;

    .navbar-nav > li {
      & > a {
        font-size: 16px;
        padding: 27px 12px 10px;
      }
    }

    .navbar-brand {
      padding: 15px 0 0 15px;
    }

    @media (min-width: $grid-float-breakpoint) {
      margin-left: $container-padding;

      .navbar-brand {
        padding-left: 0;
      }

      .navbar-nav > li {
        & > a {
          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .morecast-logo {
    width: 145px;
    height: auto;
  }

  .navbar-toggle {
    @include border-radius(0);
  }

  .top-bar {
    background: #013f70;
    height: 27px;
    line-height: 27px;
    @include clearfix;
    color: #fff;

    ul.top-bar-nav {
      float: right;
      padding: 0;
      margin: 0;

      & > li {
        display: block;
        float: left;
        margin: 0 10px 0 5px;

        & > a {
          color: #fff;
        }

        &.with-separator {
          margin-right: 5px;

          &:after {
            position: relative;
            margin: 0 0 0 10px;
            content: '|';
          }
        }

        &.has-btn {
          margin: 0;
        }
      }

      .btn {
        @include border-radius(0);
        //background: $brand-secondary;
        //color: $brand-primary;
        background: transparent;
        color: #fff;
        height: 27px;
        padding: 2px 7px 2px;
        display: block;
        margin: 0 0 0 1px;

        &:focus,
        &:hover {
          background: $brand-primary-light;
        }
      }

      .gear-icon {
        display: inline-block;
        width: 15px;
        height: 15px;
        vertical-align: middle;
        margin-top: -3px;
        fill: #fff;
      }

      .open {
        .gear-icon {
          fill: #fff;
        }
      }
    }
  }
}

.top-bar-user-img {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  @include border-radius(50%);
  margin-top: -2px;
  margin-right: 5px;
  background-size: cover;
  background-position: center center;
}

.blue-dropdown {
  .dropdown-menu {
    @include border-radius(0);
    background: $brand-primary-light;
    margin: 0;
    border: 0;

    & > li > a {
      padding-top: 8px;
      padding-bottom: 8px;
      color: #fff;

      &:focus,
      &:hover {
        background-color: $brand-secondary;
      }
    }
  }

  &.open {
    .btn {
      background: $brand-primary-light !important;
      color:#fff !important;
    }
  }
}

.header-menu-btn {
  margin-right: 10px;
}

.header-menu-btn .btn {
  height: $navbar-height;
  line-height: 63px;
  padding: 0 10px;
  border: 0;
  background: transparent;
  @include border-radius(0);
  color: #fff;
}

.header-settings {
  color: #fff;
  padding: 0 15px;
  min-width: 410px;

  table {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    td {
      padding: 5px 0;
    }
  }

  & > li:last-child table {
    border-bottom: none;
  }

  .radio {
    display: inline-block;
    margin: 0;

    label {
      padding: 0;
    }
  }
}

.social-login-btn {
  display: inline-block;
  text-align: center;
  position: relative;
  line-height: 40px;
  color: #fff;
  font-size: 1.1em;
  padding: 0 10px 0 45px;
  margin-bottom: 10px;
  min-width: 260px;
  font-weight: bold;
  @include border-radius($border-radius-base);

  &:focus,
  &:hover {
    color: #fff;
    text-decoration: none;
    opacity: 0.8;
  }

  svg {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 25px;
    height: 20px;
    font-size: 1.4em;
    fill: #fff;
  }

  &.facebook {
    background: #3b5998;
  }

  &.twitter {
    background: #55acee;
  }

  &.google {
    background: #dc4a38;
  }

  &.disconnect {
    background: #ccc;
  }
}

@media (max-width: $grid-float-breakpoint-max) {
  .navbar-collapse {
    position: fixed;
    right: -100%;
    top: 58px;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0);
    padding: 0;
    border: 0;
    display: block;
    height: auto !important;
    @include transition(background 0.3s ease-in-out);

    &.show-nav-bg {
      right: 0;
    }

    &.show-nav {
      background: rgba(0, 0, 0, 0.6);

      .navbar-nav {
        right: 0;
      }
    }

    .navbar-nav {
      position: absolute;
      right: -100%;
      top: 0;
      bottom: 0;
      width: 80%;
      background: $brand-primary-dark;
      border-left: 1px solid $brand-primary-light;
      margin: 0;
      overflow: auto;
      @include transition(right 0.3s ease-in-out);

      & > li > a {
        border-bottom: 1px solid $brand-primary-light;
      }

      & > li.open > a {
        border-bottom: 1px solid $brand-primary-light;
      }
    }
  }
}
