.widget {
  @include border-radius(5px);

  margin-bottom: $default-margin;
  padding: 15px 20px;

  h2,
  h3 {
    margin-top: 3px;
    margin-bottom: 0;
  }

  &.no-padding {
    padding: 0;
  }

  &.widget-white {
    @include border-radius(0);

    border: 1px solid lighten($btn-default-border, 13%);
    border-top: 2px solid $btn-default-border;
    background: #fff;
  }
}

.widget-head {
  @include border-radius(5px 5px 0 0);
}

.widget-text {
  @include border-radius(0 0 5px 5px);

  margin-bottom: $default-margin;
  padding: 20px;
  border: 1px solid $btn-default-border;
  border-top: 0;
  background: #fff;
}
