@mixin primary-gradient-bg() {
  background: $brand-primary-transparent;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    height: 20px;
    @include gradient-vertical($brand-primary-transparent-2, $brand-primary-transparent);
  }
}

@mixin drag-scroll() {
  -webkit-overflow-scrolling: touch;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}