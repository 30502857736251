.morecast-map-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: $body-bg;

  &.is-running {
    .handle-tooltip {
      display: block !important;
    }
  }

  &.animation-is-forecast {
    .timeline-info {
      .is-past {
        display: none;
      }

      .is-forecast {
        display: inline;
      }
    }
  }
}

.fullscreen-holder-container {
  position: relative;
  background: #fff;

  &.is-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    width: auto !important;
    height: auto !important;
    margin: 0;

    .morecast-map-btn.fullscreen {
      &.fullscreen-open {
        display: none;
      }
      &.fullscreen-close {
        display: block;
      }
    }
  }
}

.morecast-map {
  width: 100%;
  height: 100%;
}

.map-poi-info {
  display: block;
  position: relative;
  background: #fff;
  @include border-right-radius($border-radius-base);
  margin-bottom: 13px;
  padding: 3px 5px 3px 27px;
  font-size: 18px;
  @include box-shadow(0 0 8px rgba(0, 0, 0, 0.3));
  min-width: 100px;
  color: $text-color;

  &:hover {
    z-index: 5;
    text-decoration: none;
    color: $text-color;
  }

  .pin {
    position: absolute;
    left: -25px;
    bottom: -13px;
  }

  .time {
    font-size: 12px;
    text-align: center;
  }

  .info img {
    height: 25px;
    width: auto;
    vertical-align: middle;
  }
}

.ol-attribution.ol-uncollapsible {
  @include border-radius(0);
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;

  ul {
    margin-top: 4px;
    display: block;
  }
}

.morecast-map-btn-container {
  @include box-shadow(0 0 8px rgba(0, 0, 0, 0.2));
  @include border-radius($border-radius-base);

  &.fullscreen-container {
    @include border-radius($border-radius-base);
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.morecast-map-btn {
  border: 0;
  background: #fff;
  color: $brand-primary-light;
  font-weight: bold;
  font-size: 20px;
  padding: 0 5px;
  height: 30px;
  line-height: 13px;
  text-align: center;
  display: block;
  width: 30px;
  float: left;

  svg {
    width: 15px;
    height: 15px;
    fill: $brand-primary;
  }

  &:focus {
    background: #fff;
    color: $brand-primary;
    fill: $brand-primary;
    outline: none;

    svg {
      fill: $brand-primary;
    }
  }

  &.active,
  &:hover {
    background: $brand-secondary;
    color: #fff;
    outline: none;

    svg {
      fill: #fff;
    }
  }

  &.fullscreen {
    @include border-radius($border-radius-base);
  }

  &.fullscreen-close {
    display: none;
  }

  &:first-child {
    @include border-left-radius($border-radius-base);
  }

  &:last-child {
    @include border-right-radius($border-radius-base);
  }
}

.ol-zoom {
  top: 10px;
  right: 50px;
  left: auto;

  button {
    display: block;
    @include border-radius(0);
    float: left;
    margin: 0;

    &.ol-zoom-in {
      border-right: 1px solid $brand-primary-lighter;
      @include border-left-radius($border-radius-base);
    }

    &.ol-zoom-out {
      @include border-right-radius($border-radius-base);
    }
  }
}

.ol-control {
  @include box-shadow(0 0 8px rgba(0, 0, 0, 0.2));
  @include border-left-radius($border-radius-base);
  padding: 0;
  background: transparent;

  button {
    background: #fff;
    color: $brand-primary;
    font-weight: bold;
    font-size: 20px;
    height: 30px;

    &:focus {
      background: #fff;
      color: $brand-primary;
      fill: $brand-primary;
      outline: none;
    }

    &:hover {
      background: $brand-secondary;;
      color: #fff;
    }

    &.disabled {
      color: transparentize($brand-primary, 0.7);

      &:hover {
        background: #fff;
        color: transparentize($brand-primary, 0.7);
      }
    }
  }
}

.map-timeline-options {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.map-timeline {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 140px;
  @include box-shadow(0 0 8px rgba(0, 0, 0, 0.2));
  @include border-radius($border-radius-base);
  background: #fff;
  height: 30px;

  .timeline-play-btn {
    height: 30px;
    width: 30px;
    text-align: center;
    border: 0;
    background: transparent;
    position: absolute;
    left: 5px;
    top: 0;

    &:focus {
      outline: none;
    }

    svg {
      fill: $brand-secondary;
    }

    .icon-pause {
      display: none;
    }

    &.animation-running {
      .icon-play {
        display: none;
      }

      .icon-pause {
        display: block;
      }
    }
  }

  .timeline-time {
    font-size: 12px;
    position: absolute;
    left: 40px;
    top: 0;
    text-align: center;
    width: 45px;
    line-height: 30px;

    &.timeline-time-after {
      left: auto;
      right: 75px;
    }
  }

  .timeline-info {
    font-size: 12px;
    position: absolute;
    right: 5px;
    top: 0;
    text-align: center;
    width: 65px;
    line-height: 30px;

    .is-forecast {
      display: none;
    }
  }
}

.timeline-line-container {
  position: absolute;
  left: 90px;
  right: 125px;
  top: 7px;
  height: 14px;
  border-right: 1px solid $brand-primary-lighter;

  .bar {
    position: absolute;
    left: 0;
    right: 0;
    top: 6px;
    height: 2px;
    background: $brand-primary-lighter;

    &.bar-forecast {
      left: 50%;
      background: $brand-secondary;
    }
  }

  .spot {
    position: relative;
    height: 14px;
    border-left: 1px solid $brand-primary-lighter;
    float: left;
    width: 20%;

    .sub-spot {
      height: 3px;
      margin-top: 11px;
      width: 25%;
      border-right: 1px solid $brand-primary-lighter;
      float: left;
    }
  }

  .handle {
    @include transition(left 1.2s linear);
    position: absolute;
    left: 20%;
    top: -1px;
    width: 16px;
    height: 16px;
    margin-left: -8px;
    background: $brand-primary;
    @include border-radius(50%);
    z-index: 1;

    &.remove-animation {
      @include transition(left 0s linear);
    }

    &:hover {
      .handle-tooltip {
        display: block;
      }
    }

    .handle-tooltip {
      display: none;
      @include border-radius($border-radius-base);
      background: $brand-primary;
      position: absolute;
      bottom: 100%;
      left: -35px;
      margin-left: 8px;
      margin-bottom: 7px;
      width: 70px;
      height: 25px;
      line-height: 25px;
      color: #fff;
      text-align: center;

      &:after {
        @include caret('bottom', 5px, $brand-primary);
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
      }
    }
  }
}

.map-timeline-loading-overlay {
  display: none;
  position: absolute;
  bottom: 50px;
  left: 10px;
  right: 10px;
  @include box-shadow(0 0 8px rgba(0, 0, 0, 0.2));
  @include border-radius($border-radius-base);

  .progress {
    margin: 0;
    background: #fff;
    @include box-shadow(none);

    .progress-bar {
      background: $brand-secondary;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .map-timeline {
    right: 10px;
  }

  .map-timeline-options {
    bottom: 50px;
  }
}
